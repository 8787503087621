import React, { useEffect, useState } from "react";
import clsx from "clsx";
import * as Yup from "yup";
import { useFormik } from "formik";
import constants, { saveUserLocally } from "../../utils/constants";
import { useNavigate } from "react-router-dom";
import { fetchDataFromAPI } from "../../utils/api";
import logo from "../../assets/images/logo.png";

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email("Wrong email format")
    .min(3, "Minimum 3 characters")
    .max(50, "Maximum 50 characters")
    .required("Email is required"),
  password: Yup.string()
    .matches(/^\S*$/, "Space not valid in Password.")
    .min(3, "Minimum 3 characters")
    .max(50, "Maximum 50 characters")
    .required("Password is required"),
});

const initialValues = {
  email: "",
  password: "",
};

const Login = () => {
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      try {
        const body = {
          email: values?.email,
          password: values?.password,
        };

        fetchDataFromAPI("admin/login", "post", body, "")
          .then((response) => {
            console.log("admin login response: ", response);
            saveUserLocally(JSON.stringify(response?.data));
            // localStorage.setItem(
            //   constants.USER,
            //   JSON.stringify(response?.data)
            // );

            if (response?.data?.type === 2) {
              navigate("/courts");
            } else {
              navigate("/");
            }
          })
          .catch((error) => {
            console.log("login error: ", error);
            console.log("type: ", typeof error?.response?.data);
            if (
              error?.response?.data &&
              typeof error?.response?.data === "string"
            ) {
              setStatus("");
              setSubmitting(false);
              setLoading(false);
              alert(error?.response?.data);
              return;
            }
            setStatus("The login details are incorrect");
            setSubmitting(false);
            setLoading(false);
          });
      } catch (error) {
        console.log("catch error: ", error);
        setStatus("The login details are incorrect");
        setSubmitting(false);
        setLoading(false);
      }
    },
  });

  useEffect(() => {
    const USER = localStorage.getItem(constants.USER);
    if (USER) {
      navigate("/");
    }
  }, []);

  return (
    <div className="Auth-form-container">
      <img src={logo} className="logo" alt="logo_image" />

      <p className="text-black fw-bold fs-3 mt-3">Court Club - Admin</p>

      <form className="Auth-form" onSubmit={formik.handleSubmit}>
        <div className="Auth-form-content">
          {/* title */}
          <h3 className="Auth-form-title">Login</h3>

          {/* username field */}
          <div className="form-group mt-3">
            <label>Email</label>
            <input
              type="text"
              // {...formik.getFieldProps("email")}
              value={formik.values.email}
              onChange={(e) => {
                formik.setFieldValue("email", e.target.value?.trimStart());
              }}
              className={clsx(
                "form-control bg-transparent",
                {
                  "is-invalid": formik.touched.email && formik.errors.email,
                },
                {
                  "is-valid": formik.touched.email && !formik.errors.email,
                }
              )}
              placeholder="Enter email"
            />
            {formik.touched.email && formik.errors.email && (
              <div className="fv-plugins-message-container text-danger">
                <span role="alert">{formik.errors.email}</span>
              </div>
            )}
          </div>

          {/* password field */}
          <div className="form-group mt-3">
            <label>Password</label>
            <input
              type="password"
              placeholder="Enter password"
              // {...formik.getFieldProps("password")}
              value={formik.values.password}
              onChange={(e) => {
                formik.setFieldValue("password", e.target.value?.trimStart());
              }}
              className={clsx(
                "form-control bg-transparent",
                {
                  "is-invalid":
                    formik.touched.password && formik.errors.password,
                },
                {
                  "is-valid":
                    formik.touched.password && !formik.errors.password,
                }
              )}
            />
            {formik.touched.password && formik.errors.password && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block text-danger">
                  <span role="alert">{formik.errors.password}</span>
                </div>
              </div>
            )}
          </div>

          {formik.status && (
            <div className="text-danger mt-1">{formik.status}</div>
          )}

          {/* submit button */}
          <div className="d-grid gap-2 mt-4">
            <button
              type="submit"
              id="kt_sign_in_submit"
              className="btn btn-primary log-btn"
              disabled={formik.isSubmitting || !formik.isValid}
            >
              {!loading && <span className="indicator-label">Continue</span>}
              {loading && (
                <span
                  className="indicator-progress"
                  style={{ display: "block" }}
                >
                  Please wait...
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              )}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Login;
