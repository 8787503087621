import React, { useEffect, useState } from "react";
import { fetchDataFromAPI } from "../../utils/api";
import { getUser } from "../../utils/constants";
import Delete from "../../assets/svgs/delete";
import { BsFillEyeFill } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import Processing from "../../components/Processing/Processing";
import AddCourtModal from "../../components/AddCourtModal/AddCourtModal";
import { Button, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import AddAdminModal from "../../components/AddAdminModal/AddAdminModal";
import AddSubAdminModel from "../../components/AddSubAdminModel/AddSubAdminModel";

const Admins = () => {
  const [loading, setLoading] = useState(false);
  const [admins, setAdmins] = useState([]);
  const [subAdmins, setSubAdmins] = useState([]);
  const [show, setShow] = useState(false);
  const [courtAdmin, setCourtAdmin] = useState(false);

  const [deleteID, setDeleteID] = useState("");
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleShowAdmin = () => setCourtAdmin(true);
  const handleCloseAdmin = () => setCourtAdmin(false);

  const [modalShow, setModalShow] = useState(false);
  const [subModelShow, setSubModel] = useState(false);
  const [editData, setEditData] = useState();

  const navigate = useNavigate();
  useEffect(() => {
    getAdmins();
  }, []);

  const getAdmins = async () => {
    setLoading(true);
    const user = JSON.parse(getUser());
    fetchDataFromAPI("admin", "get", "", user?.authToken)
      .then((res) => {
        console.log("admins res: ", res?.data);
        setAdmins(res?.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log("admins error: ", error);
      });
  };

  const onDeleteAdmin = () => {
    const user = JSON.parse(getUser());
    fetchDataFromAPI(`admin?id=${deleteID}`, "delete", "", user?.authToken)
      .then((res) => {
        toast.success("Admin deleted successfully ✅", {
          position: toast.POSITION.TOP_RIGHT,
          className: "bg-success text-white",
        });
        getAdmins();
        handleClose();
        handleCloseAdmin();
        console.log("Admin delete res: ", res?.data);
      })
      .catch((error) => {
        toast.error("Error while deleting Admin", {
          position: toast.POSITION.TOP_RIGHT,
          className: "bg-danger text-white",
        });
        handleClose();
        console.log("Member delete error: ", error);
      });
  };

  const onClose = () => {
    setModalShow(false);
    getAdmins();
  };

  const onCloseSubModel = () => {
    setSubModel(false);
    setEditData();
    getAdmins();
  };

  useEffect(() => {
    if (editData) {
      setSubModel(true);
    }
  }, [editData]);

  return (
    <>
      <div className="dsa__users">
        {loading && <Processing />}
        <div className="d-flex align-items-center justify-content-between">
          <div className="title">Admins</div>
          <button
            className="dsa__advice__add btn h-25"
            variant="primary"
            onClick={() => setModalShow(true)}
          >
            Create Admin
          </button>
        </div>

        {/* table here */}
        <div className="user__list mt-3">
          <table border={0} cellSpacing={0} className="user__table">
            <thead>
              <tr>
                <th>Image</th>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Email</th>
                <th className="text-end">Actions</th>
              </tr>
            </thead>

            <tbody>
              {admins?.length > 0 ? (
                admins?.map((admin) =>
                  admin?.type === 1 ? (
                    <tr key={admin?._id}>
                      <td className="col-2">
                        <img
                          src={admin?.profile}
                          alt="member-pic"
                          width={50}
                          height={50}
                          className="court_image"
                        />
                      </td>
                      <td className="col-2">
                        <p>{admin?.first_name}</p>
                      </td>
                      <td className="col-2">
                        <p>{admin?.last_name}</p>
                      </td>
                      <td className="col-3">
                        <p className="note">{admin?.email || "-"}</p>
                      </td>
                      <td className="col-2 text-end align-middle h-100">
                        <div className="d-flex gap-2 justify-content-end">
                          <span
                            onClick={() =>
                              navigate(`/admins/${admin?._id}`, {
                                state: { admin: admin },
                              })
                            }
                          >
                            <BsFillEyeFill />
                          </span>
                          {/* <div className="edit_button">
                            <Edit />
                          </div> */}
                          <button
                            className="delete_button border border-0"
                            onClick={() => {
                              setDeleteID(admin?._id);
                              handleShow();
                            }}
                            disabled={JSON.parse(getUser())?._id === admin?._id}
                          >
                            <Delete />
                          </button>
                        </div>
                      </td>
                    </tr>
                  ) : null
                )
              ) : (
                <tr>
                  <td className="text-center text-dark h3 p-5" colSpan={12}>
                    No data found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>

      <div className="dsa__users">
        {/* {loading && <Processing />} */}
        <div className="d-flex align-items-center justify-content-between">
          <div className="title">Court Admins</div>
          <button
            className="dsa__advice__add btn h-25"
            variant="primary"
            onClick={() => setSubModel(true)}
          >
            Create Court Admin
          </button>
        </div>

        <div className="user__list mt-3">
          <table border={0} cellSpacing={0} className="user__table">
            <thead>
              <tr>
                <th>Image</th>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Email</th>
                <th className="text-end">Actions</th>
              </tr>
            </thead>

            <tbody>
              {admins?.length > 0 ? (
                admins?.map((admin) =>
                  admin?.type === 2 ? (
                    <tr key={admin?._id}>
                      <td className="col-2">
                        <img
                          src={admin?.profile}
                          alt="member-pic"
                          width={50}
                          height={50}
                          className="court_image"
                        />
                      </td>
                      <td className="col-2">
                        <p>{admin?.first_name}</p>
                      </td>
                      <td className="col-2">
                        <p>{admin?.last_name}</p>
                      </td>
                      <td className="col-3">
                        <p className="note">{admin?.email || "-"}</p>
                      </td>
                      <td className="col-2 text-end align-middle h-100">
                        <div className="d-flex gap-2 justify-content-end">
                          <span onClick={() => setEditData(admin)}>
                            <BsFillEyeFill />
                          </span>

                          <button
                            className="delete_button border border-0"
                            onClick={() => {
                              setDeleteID(admin?._id);
                              handleShowAdmin();
                            }}
                            disabled={JSON.parse(getUser())?._id === admin?._id}
                          >
                            <Delete />
                          </button>
                        </div>
                      </td>
                    </tr>
                  ) : null
                )
              ) : (
                <tr>
                  <td className="text-center text-dark h3 p-5" colSpan={12}>
                    No data found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>

      <AddAdminModal
        onClose={onClose}
        show={modalShow}
        onHide={() => setModalShow(false)}
      />

      <AddSubAdminModel
        onClose={onCloseSubModel}
        show={subModelShow}
        data={editData ? editData : null}
      />

      <Modal show={show} centered onHide={handleClose}>
        <Modal.Header
          closeButton
          closeVariant="white"
          className="primary_bg border-0 text-white"
        >
          <Modal.Title>Delete Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body className="primary_bg text-white">
          Are you sure you want to delete this Admin?
        </Modal.Body>
        <Modal.Footer className="primary_bg border-0">
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="danger" onClick={onDeleteAdmin}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={courtAdmin} centered onHide={handleCloseAdmin}>
        <Modal.Header
          closeButton
          closeVariant="white"
          className="primary_bg border-0 text-white"
        >
          <Modal.Title>Delete Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body className="primary_bg text-white">
          Are you sure you want to delete the court-admin
        </Modal.Body>
        <Modal.Footer className="primary_bg border-0">
          <Button variant="secondary" onClick={handleCloseAdmin}>
            Close
          </Button>
          <Button variant="danger" onClick={onDeleteAdmin}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Admins;
