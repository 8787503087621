import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Processing from "../../components/Processing/Processing";
import { useFormik } from "formik";
import * as Yup from "yup";
import { fetchDataFromAPI } from "../../utils/api";
import { getUser } from "../../utils/constants";
import { toast } from "react-toastify";
import { io } from "socket.io-client";
import zero from "../../assets/svgs/zero.svg";
import one from "../../assets/svgs/one.svg";
import two from "../../assets/svgs/two.svg";
import threeToFive from "../../assets/svgs/three-five.svg";
import sixToNine from "../../assets/svgs/six-nine.svg";
import tenPlus from "../../assets/svgs/tenplus.svg";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Accordion, Button, Form, Modal } from "react-bootstrap";
import moment from "moment/moment";
import Multiselect from "multiselect-react-dropdown";
import Select from "react-select";
import AddEvents from "./AddEvents";

const CourtDetail = () => {
  const { state } = useLocation();

  const { court } = state;

  console.log("court", court);

  const [loading, setLoading] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const [buttonValue, setButtonValue] = useState("Edit");
  const [buttonValueEvent, setButtonValueEvent] = useState("Edit");

  const [file, setFile] = useState(null);
  const [url, setURL] = useState(null);
  const [urlEvent, setURLEvent] = useState(null);
  const [imgUrl, setImgURL] = useState();
  const [courtData, setCourtData] = useState();
  console.log("courtData", courtData);

  const [selectedSlot, setSelectedSlot] = useState();
  const navigate = useNavigate();
  const fileInputRef = useRef();
  const [checked, setChecked] = useState(false);
  const [checkedWebHook, setCheckedWebHook] = useState(false);
  const [timeSlots, setTimeSlots] = useState([]);
  const [selectedSlots, setSelectedSlots] = useState([]);
  const handleChange = () => setChecked(!checked);
  const handleChangeWebHook = () => setCheckedWebHook(!checkedWebHook);
  const [options, setOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);

  const coursId = selectedOptions.map((city) => city.id);
  const [courtAllData, setAllCourt] = useState([]);
  const [showAddEvent, setShowAddEvent] = useState(false);
  const [deleteModel, setDeleteModel] = useState(false);
  const [editData, setEditData] = useState();
  const [deleteID, setDeleteID] = useState();
  const user = JSON.parse(getUser());

  console.log("user", user);

  const handleCloseDelete = () => {
    setDeleteModel(false);
  };

  const onDeleteEvent = () => {};

  const handleSelect = (selectedList, selectedItem) => {
    setSelectedOptions(selectedList);
  };

  const handleRemove = (selectedList, removedItem) => {
    setSelectedOptions(selectedList);
  };

  const socket = io("https://admin-cc.serenpedia.com:3000/", {
    transports: ["websocket"],
  });

  // const socket = io('http://192.168.29.59:3000/', {
  //   transports: ['websocket'],
  // });

  // const socket = io('https://devnode.coderkubes.com:3000/', {
  //   transports: ['websocket'],
  // });

  // const socket = io('https://court-club.onrender.com/', {
  //   transports: ['websocket'],
  // });

  useEffect(() => {
    /* 7 - 11 - 2024 */
    getCourt();

    getCourtData();
    getTimeSlots();

    socket.on("connect", onSocketConnect);

    return () => {
      socket.emit("leaveTimeSlot", { courtId: court?._id });
      socket.off("onTimeSlot", onTimeSlotEvent);
      // socket.disconnect();
    };
  }, []);

  useEffect(() => {
    if (editData) {
      setShowAddEvent(true);
    }
  }, [editData]);

  useEffect(() => {
    if (deleteID) {
      setDeleteModel(true);
    }
  }, [deleteID]);

  const getCourt = () => {
    const user = JSON.parse(getUser());

    fetchDataFromAPI("Court/names", "get", " ", user?.authToken)
      .then((res) => {
        setAllCourt(res?.data);
        console.log("res ---->", res);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  // const options = [
  //   {name: 'Option 1', id: 1},
  //   {name: 'Option 2', id: 2},
  //   {name: 'Option 3', id: 3},
  // ];

  useEffect(() => {
    const transformedOptions = courtAllData?.map((item) => ({
      name: item?.title,
      id: item?._id,
    }));
    setOptions(transformedOptions);
  }, [courtAllData]);

  // useEffect(() => {
  //   getAllCourts();
  // }, []);

  // const getAllCourts = async () => {
  //   setLoading(true);
  //   const user = JSON.parse(getUser());
  //   fetchDataFromAPI('Court', 'get', '', user?.authToken)
  //     .then((res) => {
  //       console.log('courts####:', res?.data);
  //     })
  //     .catch((error) => {
  //       console.log('courts error: ', error);
  //     });
  // };

  const onSocketConnect = () => {
    console.log("connected");
    if (socket.connected) {
      socket.on("onTimeSlot", onTimeSlotEvent);
      socket.emit("joinTimeSlot", {
        courtId: court?._id,
        date: moment().format("YYYY-MM-DD"),
      });
    }
  };

  const onTimeSlotEvent = (data) => {
    console.log("is socket connected: ", socket.connected);
    console.log("onTimeSlot method data in socket: ", data);
    // let event = data?.event;

    // if (event) {
    //   setLoading(true);
    //   eventinfo.setFieldValue('title', event?.title);
    //   eventinfo.setFieldValue('info', event?.info);
    //   eventinfo.setFieldValue('date', moment(event?.date).format('YYYY-MM-DD'));
    //   eventinfo.setFieldValue('courtEvent', event?.courtEvent);
    //   eventinfo.setFieldValue('timeSlots', event?.timeSlot);
    //   eventinfo.setFieldValue('sendWebook', event?.event);
    //   eventinfo.setFieldValue(
    //     'timeSlotsCourt',
    //     event?.court_id?.map((item) => item?._id),
    //   );
    //   setLoading(false);
    // }

    setCourtData(data);
    setSelectedSlot((prev) => {
      if (prev?.timeSlot?._id) {
        const pass = data?.onTheCourt?.filter(
          (item) => item?.timeSlot?._id === prev?.timeSlot?._id
        );
        console.log("pass: ", pass);
        return pass[0];
      }
      return data?.onTheCourt[0];
    });
  };

  const getCourtData = () => {
    setLoading(true);
    formik.setFieldValue("title", court?.title);
    formik.setFieldValue("subtitle", court?.subTitle);
    formik.setFieldValue("info", court?.info);

    setLoading(false);
  };

  const getTimeSlots = () => {
    const user = JSON.parse(getUser());
    fetchDataFromAPI("timeslot", "get", "", user?.authToken)
      .then((res) => {
        console.log("timeslot res: ", res?.data);
        setTimeSlots(res?.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log("timeslot error: ", error);
      });
  };

  const handleEditorChange = (html) => {
    formik.setFieldValue("info", html);
    // If the editor is changed, consider it as editing and enable the form for update
    if (!isEditable) {
      setIsEditable(true);
      setButtonValue("Update");
    }
  };

  const handleEditorChangeEvent = (html) => {
    eventinfo.setFieldValue("info", html);
  };

  const validationSchema = Yup.object().shape({
    title: Yup.string().required("Please enter court title"),
    subtitle: Yup.string().required("Please enter court subtitle"),
    info: Yup.string().notRequired(),
  });

  const formik = useFormik({
    initialValues: {
      title: "",
      subtitle: "",
      info: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      //   if (!file) {
      //     setFileError("Please select image for court");
      //     return;
      //   }
      console.log("values: ", values);
      const user = JSON.parse(getUser());
      const data = {
        title: values.title,
        subTitle: values.subtitle,
        image: url !== null && url !== undefined ? url : court?.image,
        info: values.info,
      };

      console.log("data", data);
      fetchDataFromAPI(
        `Court/update?id=${court?._id}`,
        "post",
        data,
        user?.authToken
      )
        .then((res) => {
          toast.success("Court updated successfully ✅", {
            position: toast.POSITION.TOP_RIGHT,
            className: "bg-success text-white",
          });
          navigate("/courts");
          console.log("update court res: ", res?.data);
        })
        .catch((error) => {
          toast.error("Error while updating Court", {
            position: toast.POSITION.TOP_RIGHT,
            className: "bg-danger text-white",
          });
          console.log("Error while updating court: ", error);
        });
    },
  });

  const validationSchemaEvent = Yup.object().shape({
    // title: Yup.string().required('Please enter  title'),
    // info: Yup.string().required('Please enter  subtitle'),
    // date: Yup.string().required('Please enter date'),
    courtEvent: Yup.string().notRequired(),
    timeSlots: Yup.mixed().notRequired(),
    sendWebook: Yup.boolean().notRequired(),
  });

  const eventinfo = useFormik({
    initialValues: {
      title: "",
      info: "",
      date: "",
      courtEvent: "",
      timeSlots: [],
      sendWebook: "",
      timeSlotsCourt: [],
    },
    validationSchema: validationSchemaEvent,
    onSubmit: async (values) => {
      console.log("values", values);
      const user = JSON.parse(getUser());

      const body = {
        image:
          urlEvent !== null && urlEvent !== undefined
            ? urlEvent
            : "https://admin-cc.serenpedia.com:3000/uploads/file-17229482114978606.png",
        title: values.title,
        info: values.info,
        date: values.date,
        courtEvent: values.courtEvent ? values.courtEvent : false,
        timeSlot: values.timeSlots,
        event: values.sendWebook ? values.sendWebook : false,
        court_id: values?.timeSlotsCourt,
      };

      console.log("body", body);

      if (courtData?.event?.length) {
        console.log("edit");
        fetchDataFromAPI(
          `event/updateEvent?id=${courtData?.event?._id}`,
          "put",
          body,
          user?.authToken
        )
          .then((res) => {
            console.log("res", res);
            toast.success("Event created successfully ✅", {
              position: toast.POSITION.TOP_RIGHT,
              className: "bg-success text-white",
            });
            // window.location.reload();
          })
          .catch((error) => {
            console.log("error", error);
          });
      } else {
        fetchDataFromAPI(
          `event/createEvent?court_id=${courtData?._id}`,
          "post",
          body,
          user?.authToken
        )
          .then((res) => {
            console.log("res", res);
            toast.success("Event created successfully ✅", {
              position: toast.POSITION.TOP_RIGHT,
              className: "bg-success text-white",
            });
            // window.location.reload();
          })
          .catch((error) => {
            console.log("error", error);
          });
      }
    },
  });

  const enableEditing = () => {
    setIsEditable(true);
    setButtonValue("Update");
  };

  const enableEditingEvent = () => {
    setIsEditable(true);
    setButtonValueEvent("Update");
  };

  const handleFileChange = (e) => {
    // console.log("e?.target?.files[0]: ", e?.target?.files[0]);
    setFile(e?.target?.files[0]);
    const image = e?.target?.files[0];
    const data = new FormData();
    data.append("image", image);
    const user = JSON.parse(getUser());
    fetchDataFromAPI("upload", "post", data, user?.authToken)
      .then((res) => {
        console.log("res: ", res?.data);
        setURL(res?.data);
        setImgURL(res?.data);
      })
      .catch((error) => {
        console.log("error: ", error);
      });
  };

  const handleFileChangeEvent = (e) => {
    // console.log("e?.target?.files[0]: ", e?.target?.files[0]);
    setFile(e?.target?.files[0]);
    const image = e?.target?.files[0];
    const data = new FormData();
    data.append("image", image);
    const user = JSON.parse(getUser());
    fetchDataFromAPI("upload", "post", data, user?.authToken)
      .then((res) => {
        console.log("res: ", res?.data);
        setURLEvent(res?.data);
        setImgURL(res?.data);
      })
      .catch((error) => {
        console.log("error: ", error);
      });
  };

  const selectSlot = (item) => {
    setSelectedSlot(item);
  };

  const onSelectTimeSlot = (e) => {
    if (e?.target?.value) {
      eventinfo?.setFieldValue("timeSlots", [
        ...eventinfo?.values?.timeSlots,
        e?.target?.value,
      ]);
    }
  };

  const onSelectTCourtID = (e) => {
    if (e?.target?.value) {
      eventinfo?.setFieldValue("timeSlotsCourt", [
        ...eventinfo?.values?.timeSlotsCourt,
        e?.target?.value,
      ]);
    }
  };

  const onRemoveSelectedCourtID = (item) => {
    const removed = eventinfo?.values?.timeSlotsCourt?.filter(
      (sitem) => sitem != item
    );
    // setSelectedSlots(removed);
    eventinfo.setFieldValue("timeSlotsCourt", [...removed]);
  };

  const onRemoveSelectedTimeSlot = (item) => {
    const removed = eventinfo?.values?.timeSlots?.filter(
      (sitem) => sitem != item
    );
    // setSelectedSlots(removed);
    eventinfo.setFieldValue("timeSlots", [...removed]);
  };

  const btnDelete = () => {
    const user = JSON.parse(getUser());

    fetchDataFromAPI(
      `event/deleteEvent?id=${court?._id}&event_id=${deleteID}`,
      "delete",
      "",
      user?.authToken
    )
      .then((res) => {
        toast.success("Event deleted successfully ✅", {
          position: toast.POSITION.TOP_RIGHT,
          className: "bg-success text-white",
        });
        // window.location.reload();
        navigate("/courts");

        console.log("delete court res: ", res?.data);
      })
      .catch((error) => {
        console.log("Error while deleting event: ", error);
      });
  };

  const handleCloseAddEvent = () => {
    setShowAddEvent(false);
  };

  return (
    <div className="dsa__advice pb-5">
      {loading && <Processing />}
      {/* header */}
      <div className="title">Court Details</div>

      <div className="advice_form text-black">
        <div className="d-flex flex-column align-items-center justify-content-center">
          <img
            src={url ? url : court?.image}
            className="rounded-circle avatar"
            width={100}
            height={100}
          />
          {isEditable && (
            <div
              className="icon-style disabled"
              onClick={() => isEditable && fileInputRef?.current?.click()}
            >
              <i class="bi bi-pencil-square"></i> Edit
            </div>
          )}
          <input
            ref={fileInputRef}
            accept="image/*"
            className="d-none"
            type="file"
            onChange={handleFileChange}
          />
        </div>

        <div className="d-flex flex-column mt-3">
          <label className="category_label">Title</label>
          <input
            type="text"
            name="title"
            disabled={isEditable ? false : true}
            className="form form-control bg-transparent text-black"
            // {...formik.getFieldProps("username")}
            value={formik.values.title}
            onChange={(e) => {
              formik.setFieldValue("title", e.target.value?.trimStart());
            }}
          />
          {formik.touched.title && formik.errors.title && (
            <div className="text-danger">{formik.errors.title}</div>
          )}
        </div>

        <div className="d-flex flex-column mt-3">
          <label className="category_label">Subtitle</label>
          <input
            type="text"
            name="subtitle"
            disabled={isEditable ? false : true}
            className="form form-control bg-transparent text-black type_password"
            // {...formik.getFieldProps("email")}
            value={formik.values.subtitle}
            onChange={(e) => {
              formik.setFieldValue("subtitle", e.target.value?.trimStart());
            }}
          />
          {formik.touched.subtitle && formik.errors.subtitle && (
            <div className="text-danger">{formik.errors.subtitle}</div>
          )}
        </div>

        <div className="d-flex flex-column mt-3">
          <label className="category_label">Info</label>
          <ReactQuill
            theme="snow"
            value={formik.values.info}
            onChange={handleEditorChange}
            style={{ height: 200, color: "#000000" }}
            // onBlur={formik.handleBlur}

            // style={{height: 200}}
          />
        </div>

        <div className="d-flex mt-5 justify-content-end gap-4">
          <button
            className="form_button btn advice__category"
            onClick={() => {
              window.history.back();
            }}
          >
            Back
          </button>
          <button
            className="form_button btn advice__category"
            disabled={
              buttonValue === "Update" &&
              formik.values.title === court?.title &&
              formik.values.subtitle === court?.subTitle &&
              formik.values.info === court.info &&
              url === ""
            }
            onClick={
              buttonValue === "Update" ? formik.handleSubmit : enableEditing
            }
          >
            {buttonValue}
          </button>
        </div>

        {/* 7 - 11 - 2024  */}

        {/* events for users */}

        <div className="d-flex mt-5 justify-content-end gap-4">
          <button
            className="form_button btn advice__category"
            onClick={() => setShowAddEvent(true)}
          >
            Create new event
          </button>
        </div>

        <Accordion defaultActiveKey="0" className="accordion-container">
          <Accordion defaultActiveKey="0" className="accordion-container">
            {courtData?.event && courtData?.event?.length > 0 ? (
              courtData.event
                .filter((item) => item?.host_id === court?._id)
                .map((event, index) => (
                  <Accordion.Item
                    eventKey={index.toString()}
                    key={event._id}
                    className="accordion-item"
                  >
                    <Accordion.Header className="accordion-header">
                      {event?.title}
                    </Accordion.Header>

                    <Accordion.Body className="accordion-body">
                      <div className="card">
                        <div className="card-body">
                          <div className="avatar-container">
                            <img
                              src={
                                event.image ||
                                require("../../assets/images/EventImg.png")
                              }
                              className="img-avatar"
                              width={100}
                              height={100}
                              alt="Event"
                            />
                          </div>

                          <div className="d-flex flex-column mt-3">
                            <label className="category_label">Title</label>
                            <input
                              type="text"
                              name="title"
                              disabled
                              className="form form-control bg-transparent text-black"
                              value={event?.title || ""}
                            />
                          </div>

                          <div className="d-flex flex-column mt-3">
                            <label className="category_label">Info</label>
                            <ReactQuill
                              theme="snow"
                              value={event?.info || ""}
                              readOnly
                              style={{
                                height: 200,
                                color: "#000000",
                                marginBottom: 50,
                              }}
                            />
                          </div>

                          <div className="d-flex flex-column mt-3">
                            <label className="category_label">Date</label>
                            <input
                              type="date"
                              name="date"
                              className="form form-control bg-transparent text-black"
                              value={
                                event?.date
                                  ? moment(event.date).format("YYYY-MM-DD")
                                  : ""
                              }
                              min={moment().format("YYYY-MM-DD")}
                            />
                          </div>

                          <div className="d-flex flex-column mt-3">
                            <label className="category_label">Timeslots</label>
                            <select className="form form-select" disabled>
                              <option>Select TimeSlots</option>
                              {event?.timeSlots?.map((item) => (
                                <option
                                  key={item?._id}
                                  value={item?._id}
                                  disabled
                                >
                                  {item?.start_time + " - " + item?.end_time}
                                </option>
                              ))}
                            </select>
                            <div className="d-flex align-items-center mt-3 gap-3">
                              {event?.timeSlot?.length > 0 &&
                                event.timeSlot.map((sitem) => {
                                  const matchedTimeSlot = timeSlots?.find(
                                    (item) => item?._id === sitem
                                  );
                                  return matchedTimeSlot ? (
                                    <div className="card border-0" key={sitem}>
                                      <div className="d-flex align-items-center justify-content-center gap-3 p-2">
                                        <p className="m-0">
                                          {matchedTimeSlot?.start_time} -{" "}
                                          {matchedTimeSlot?.end_time}
                                        </p>
                                      </div>
                                    </div>
                                  ) : null;
                                })}
                            </div>
                          </div>

                          <div className="d-flex flex-column mt-3">
                            <Form.Check
                              type="switch"
                              id={`custom-switch-${event._id}`}
                              label="On court event (Sign up via timeslot)"
                              checked={event?.courtEvent?.some(
                                (id) => id === court?._id
                              )}
                              className="custom-switch"
                            />
                          </div>

                          <div className="d-flex flex-column mt-3">
                            <label className="category_label">
                              Show at courts
                            </label>
                            <select className="form form-select" disabled>
                              <option>Select Show at courts</option>
                              {courtAllData?.map((item) => (
                                <option
                                  key={item?._id}
                                  value={item?._id}
                                  disabled={event?.timeSlotsCourt?.includes(
                                    item?._id
                                  )}
                                >
                                  {item?.title} - {item?.subTitle}
                                </option>
                              ))}
                            </select>
                          </div>

                          <div className="d-flex flex-column mt-3">
                            <Form.Check
                              type="switch"
                              id={`event-switch-${event._id}`}
                              label="3x3event (send webhook)"
                              className="custom-switch"
                              checked={!!event?.event}
                              style={{ marginTop: "20px" }}
                            />
                          </div>

                          <div className="d-flex mt-5 justify-content-start gap-4 m-2">
                            <button
                              className="form_button btn advice__category"
                              onClick={() => setDeleteID(event?._id)}
                            >
                              Delete
                            </button>
                            <button
                              className="form_button btn advice__category"
                              onClick={() => setEditData(event)}
                            >
                              Edit
                            </button>
                          </div>
                        </div>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                ))
            ) : (
              <p className="text-center mt-3">No events available.</p>
            )}
          </Accordion>
        </Accordion>

        {/* timeslots and users */}

        {/* users for particular timeslot */}

        {user?.type === 1 && (
          <>
            <div>
              <label className="form-label fw-bold h4">Players</label>
              {selectedSlot && (
                <div className="row align-items-center mt-5 justify-content-center">
                  {selectedSlot?.users?.length > 0 ? (
                    selectedSlot?.users?.map((user, index) => (
                      <div
                        key={user?._id}
                        className="d-flex flex-column align-items-center col-sm-4 col-md-3 col-lg-3 m-1"
                      >
                        <img
                          src={user?.profile}
                          alt="user-picture"
                          className="rounded-circle"
                          width={70}
                          height={70}
                        />
                        <span className="h6 mt-2">{user?.username}</span>
                      </div>
                    ))
                  ) : (
                    <p className="h2 text-center">No users have joined.</p>
                  )}
                </div>
              )}
            </div>

            {/* timeslot listing */}
            <div className="row align-items-center mt-5 justify-content-center">
              <label className="form-label fw-bold h4">Game Time</label>
              {courtData &&
                courtData?.onTheCourt?.map((timeSlot, index) => (
                  <div
                    className={
                      selectedSlot?.timeSlot?._id === timeSlot?.timeSlot?._id
                        ? "card-border m-2 card col-sm-5 col-md-4 col-lg-3 align-items-center py-2 px-1 rounded"
                        : "border-0 m-2 card col-sm-5 col-md-4 col-lg-3 align-items-center py-2 px-1 rounded"
                    }
                    role="button"
                    onClick={() => selectSlot(timeSlot)}
                  >
                    <img
                      src={
                        timeSlot?.users?.length === 0
                          ? zero
                          : timeSlot?.users?.length === 1
                          ? one
                          : timeSlot?.users?.length === 2
                          ? two
                          : 3 <= timeSlot?.users?.length <= 5
                          ? threeToFive
                          : 6 <= timeSlot?.users?.length <= 9
                          ? sixToNine
                          : timeSlot?.users?.length >= 10
                          ? tenPlus
                          : tenPlus
                      }
                      alt="player-count-icon"
                      className="mt-3"
                    />
                    <span className="mt-3">
                      {timeSlot?.users?.length + " players"}
                    </span>
                    <span className="mt-2 mb-3">
                      {timeSlot?.timeSlot?.start_time +
                        " - " +
                        timeSlot?.timeSlot?.end_time}
                    </span>
                  </div>
                ))}
            </div>
          </>
        )}

        {/* 7 - 11 - 2024  */}
        <AddEvents
          show={showAddEvent}
          handleClose={handleCloseAddEvent}
          courtData={courtData}
          court_id={court?._id}
          data={editData}
        />

        <Modal show={deleteModel} centered onHide={handleCloseDelete}>
          <Modal.Header
            closeButton
            closeVariant="white"
            className="primary_bg border-0 text-white"
          >
            <Modal.Title>Delete Confirmation</Modal.Title>
          </Modal.Header>
          <Modal.Body className="primary_bg text-white">
            Are you sure you want to delete this Event?
          </Modal.Body>
          <Modal.Footer className="primary_bg border-0">
            <Button variant="secondary" onClick={handleCloseDelete}>
              Close
            </Button>
            <Button variant="danger" onClick={btnDelete}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export default CourtDetail;
