import React, { useEffect, useState } from "react";
import { fetchDataFromAPI } from "../../utils/api";
import constants, { getUser } from "../../utils/constants";
import { useNavigate } from "react-router-dom";

const Dashboard = () => {
  const [dashboard, setDashboard] = useState();
  const navigate = useNavigate();
  useEffect(() => {
    getDashboardData();
  }, []);

  const getDashboardData = () => {
    const user = JSON.parse(getUser());
    fetchDataFromAPI("admin/dashboard", "get", "", user?.authToken)
      .then((res) => {
        setDashboard(res?.data);
        console.log("dash res: ", res?.data);
      })
      .catch((error) => {
        console.log("dash error: ", error);
        if (error?.response?.status == 401) {
          localStorage.removeItem(constants.USER);
          navigate("/login");
        }
      });
  };

  return (
    <>
      <div className="dsa__users">
        <div className="title mx-4">Dashboard</div>

        <section className="section dashboard">
          <div className="row mx-2 mt-2">
            <div className="col-lg-12">
              <div className="row">
                {/* courts */}
                <div
                  className="col-xxl-3 col-sm-6 col-md-4"
                  role="button"
                  onClick={() => navigate("/courts")}
                >
                  <div className="card border-0 info-card sales-card">
                    <div className="card-body">
                      <h5 className="card-title">Total Courts</h5>

                      <div className="d-flex align-items-center">
                        <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                          <i className="bi bi-building"></i>
                          {/* <img
                            src={logo}
                            alt="court-picture"
                            width={30}
                            height={30}
                          /> */}
                        </div>
                        <div className="ps-3">
                          <h3>{dashboard?.courts}</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* members */}
                <div
                  className="col-xxl-3 col-sm-6 col-md-4"
                  role="button"
                  onClick={() => navigate("/members")}
                >
                  <div className="card border-0 info-card sales-card">
                    <div className="card-body">
                      <h5 className="card-title">Total Members</h5>

                      <div className="d-flex align-items-center">
                        <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                          <i className="bi bi-people"></i>
                        </div>
                        <div className="ps-3">
                          <h3>{dashboard?.users}</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* levels */}
                <div
                  className="col-xxl-3 col-sm-6 col-md-4"
                  role="button"
                  onClick={() => navigate("/levels")}
                >
                  <div className="card border-0 info-card sales-card">
                    <div className="card-body">
                      <h5 className="card-title">Total Levels</h5>

                      <div className="d-flex align-items-center">
                        <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                          <i className="bi bi-bar-chart"></i>
                        </div>
                        <div className="ps-3">
                          <h3>{dashboard?.levels}</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* admins */}
                <div
                  className="col-xxl-3 col-sm-6 col-md-4"
                  role="button"
                  onClick={() => navigate("/admins")}
                >
                  <div className="card border-0 info-card sales-card">
                    <div className="card-body">
                      <h5 className="card-title">Total Super Admins</h5>

                      <div className="d-flex align-items-center">
                        <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                          <i className="bi bi-person-gear"></i>
                        </div>
                        <div className="ps-3">
                          <h3>{dashboard?.superAdmins}</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="col-xxl-3 col-sm-6 col-md-4"
                  role="button"
                  onClick={() => navigate("/admins")}
                >
                  <div className="card border-0 info-card sales-card">
                    <div className="card-body">
                      <h5 className="card-title">Total Admin</h5>

                      <div className="d-flex align-items-center">
                        <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                          <i className="bi bi-person-gear"></i>
                        </div>
                        <div className="ps-3">
                          <h3>{dashboard?.admins}</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* timeslots */}
                <div
                  className="col-xxl-3 col-sm-6 col-md-4"
                  role="button"
                  onClick={() => navigate("/timeslots")}
                >
                  <div className="card border-0 info-card sales-card">
                    <div className="card-body">
                      <h5 className="card-title">Total Timeslots</h5>

                      <div className="d-flex align-items-center">
                        <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                          <i className="bi bi-clock"></i>
                        </div>
                        <div className="ps-3">
                          <h3>{dashboard?.times}</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Dashboard;
